import axios from 'axios'

export default [

  // new 

  {
    key: 'first',
    icon: 'fad fa-fist-raised',
    title: 'First !',
    description: "Etre le premier dans le chat",
    color: {
      "background-color": "#FFE53B",
      "background-image": "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)"
    },
    point: 25,
    category: 'new'
  },

  {
    key: 'iamvip',
    icon: 'fad fa-gem',
    title: 'Very Important Person',
    description: "Devenir VIP",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    point: 50,
    category: 'new'
  },

  {
    key: 'snow-train',
    icon: 'fad fa-snowman',
    title: 'Chute de boule de neige',
    description: "Etre sous une chute de boule le neige",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 25,
    category: 'new'
  },


  {
    key: 'send-cookie',
    icon: 'fad fa-cookie',
    title: 'COOKIE',
    description: "Offrez un cookie",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    point: 15,
    category: 'new'
  },

  {
    key: 'receive-cookie',
    icon: 'fad fa-cookie',
    title: 'COOKIE',
    description: "Recevez un cookie",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    point: 15,
    category: 'new'
  },

  {
    key: 'categories-level-1',
    icon: 'fad fa-album-collection',
    title: 'Découverte',
    description: "Assistez a 2 stream dans des categories differentes",
    color: {
      "background-color": "#2BFF88",
      "background-image": "linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)"
    },
    level: 1,
    require: {
      field: 'categories',
      value: 2
    },
    point: 10,
    category: 'new'
  },

  {
    key: 'categories-level-2',
    icon: 'fad fa-album-collection',
    title: 'Découverte',
    description: "Assistez a 5 stream dans des categories differentes",
    color: {
      "background-color": "#2BFF88",
      "background-image": "linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)"
    },
    level: 2,
    require: {
      field: 'categories',
      value: 5
    },
    point: 25,
    category: 'new'
  },

  {
    key: 'categories-level-3',
    icon: 'fad fa-album-collection',
    title: 'Découverte',
    description: "Assistez a 10 stream dans des categories differentes",
    color: {
      "background-color": "#2BFF88",
      "background-image": "linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)"
    },
    level: 3,
    require: {
      field: 'categories',
      value: 10
    },
    point: 50,
    category: 'new'
  },

  {
    key: 'categories-level-4',
    icon: 'fad fa-album-collection',
    title: 'Découverte',
    description: "Assistez a 25 stream dans des categories differentes",
    color: {
      "background-color": "#2BFF88",
      "background-image": "linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)"
    },
    level: 4,
    require: {
      field: 'categories',
      value: 25
    },
    point: 100,
    category: 'new'
  },

  {
    key: 'emote-aife',
    class: 'aife',
    title: 'J\'ai l\'art Aife',
    description: "J'ai pas l'art Aife",
    color: {
      "background-color": "#8fd3f4",
      "background-image": "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)"
    },
    point: 25,
    hidden: true,
    noHideName: true,
    category: 'new'
  },

  // social 

  {
    key: 'social-instagram',
    icon: 'fab fa-instagram',
    title: 'Un coup d\'oeil sur Insta',
    description: "Rien n'oblige a me suive, passe juste voir mon Instagram",
    color: {
      "background-color": "#f5576c",
      "background-image": "linear-gradient(120deg, #f093fb 0%, #f5576c 100%)"
    },
    point: 50,
    url: 'https://www.instagram.com/alxios_/',
    category: 'social'
  },

  {
    key: 'social-twitter',
    icon: 'fab fa-twitter',
    title: 'Un coup d\'oeil sur Twitter',
    description: "Rien n'oblige a me suive, passe juste voir mon Twitter",
    color: {
      "background-color": "#c2e9fb",
      "background-image": "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)"
    },
    point: 50,
    url: 'https://twitter.com/alxios_',
    category: 'social'
  },

  {
    key: 'social-discord',
    icon: 'fab fa-discord',
    title: 'Un coup d\'oeil sur Discord',
    description: "Rien n'oblige a le rejoindre, passe juste voir notre Discord",
    color: {
      "background-color": "#764ba2",
      "background-image": "linear-gradient(135deg, #667eea 0%, #764ba2 100%)"
    },
    point: 50,
    url: 'https://discord.gg/CDa3uAXbAG',
    category: 'social'
  },

  // Noel 2021

  {
    key: 'event-noel-2021',
    icon: 'fad fa-wreath',
    title: 'Un joyeux Noël 2021',
    description: "Avoir participer a l'évent de noël 2021",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 50,
    category: 'noel2021'
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 1,000 Boules de Noël",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 1,
    require: {
      field: 'boule2noel',
      value: 1_000
    },
    point: 10,
    category: 'noel2021'
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 10,000 Boules de Noël",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 2,
    require: {
      field: 'boule2noel',
      value: 10_000
    },
    point: 25,
    category: 'noel2021'
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 25,000 Boules de Noël",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 3,
    require: {
      field: 'boule2noel',
      value: 25_000
    },
    point: 50,
    category: 'noel2021'
  },

  // UNLOCKABLE 
  
  {
    key: 'team',
    icon: 'fad fa-flag',
    title: 'J\'en suis !',
    description: "Rejoindre une equipe lors de l'event des 1 ans",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 10
  },

  {
    key: 'all-team',
    icon: 'fad fa-flag-checkered',
    title: 'A tout les râteliers',
    description: "Avoir fait partie au moins une fois de chaque equipe disponible",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 25
  },

  {
    key: 'event-noel',
    icon: 'fad fa-wreath',
    title: 'Les Boules de Noël',
    description: "Avoir participer a l'évent de noel 2020",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 50
  },

  {
    key: 'shop',
    icon: 'fad fa-shopping-cart',
    title: 'C\'est gratuit !',
    description: "Acheter un jeu sur la boutique",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 25
  },

  // live

  {
    key: 'team-win',
    icon: 'fad fa-trophy',
    title: 'Esprit d\'équipe',
    description: "Faire partis de l'équipe gagante en fin de semaine",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #fca7eb 0%, #470c45 100%)"
    },
    point: 50
  },

  // emotes 

  {
    key: 'piano',
    icon: 'fad fa-piano',
    title: 'Chill Radio',
    description: "Assister a un live musicale",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #fca7eb 0%, #470c45 100%)"
    },
    point: 25
  },

  //

  {
    key: 'hiver',
    icon: 'fad fa-snowflake',
    title: 'Aucune corruption',
    description: "Dire `Je préfère l'hiver a l'été` dans le chat",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    point: 10
  },

  {
    key: 'ete',
    icon: 'fad fa-temperature-high',
    title: 'Aucune corruption',
    description: "Je n'ai pas respecter la regle",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    hidden: true,
    point: 25
  },

  {
    key: 'emote-alxios',
    icon: 'fad fa-heart',
    title: 'Sentiment d\'appartenance',
    description: "Utiliser les emotes d'Alxios",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    point: 10
  },

  //  

  {
    key: 'emote-skerax',
    class: 'skerax',
    title: 'Team caparace',
    description: "Apparetenir a la team carapace",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 25,
    hidden: true
  },

  {
    key: 'emote-encre',
    class: 'encre',
    title: 'Le GO TO GO',
    description: "Moi aussi j'aime bien Gris et la béchamel",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    point: 25,
    hidden: true
  },

  {
    key: 'gift-send',
    icon: 'fad fa-hand-holding-box',
    title: 'Le beau geste',
    description: "Offrir des asticots a un autres (!gift)",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    point: 10
  },

  {
    key: 'gift-receive',
    icon: 'fad fa-gift',
    title: 'Un généreux donateur',
    description: "Recevoir un cadeau",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    point: 10
  },

  {
    key: 'gift-alxios',
    icon: 'fad fa-gift-card',
    title: 'Un dons du ciel',
    description: "Recevoir des asticots de la part d'Alxios",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    point: 10
  },

  {
    key: 'bet',
    icon: 'fad fa-coins',
    title: 'All In',
    description: "Parier 1,000 asticot ( !bet 1000 )",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    point: 10
  },

  {
    key: 'bet-win',
    icon: 'fad fa-dice',
    title: 'Grand gagnant',
    description: "Gaganer 10 paries (!bet)",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    require: {
      field: 'betWon',
      value: 10
    },
    point: 25
  },

  {
    key: 'bet-loose',
    icon: 'fad fa-dice-one',
    title: 'Eternelle perdant',
    description: "Perdre 10 paries (!bet)",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    require: {
      field: 'betLost',
      value: 10
    },
    point: 25
  },

  {
    key: 'ban',
    icon: 'fad fa-gavel',
    title: 'Ban Hammer',
    description: "Tu est la honte du chat d'Alxios",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    hidden: true,
    point: 10
  },

  {
    key: 'bug',
    icon: 'fad fa-bug',
    title: 'Pas cette fois',
    description: "Avoir essayer (mais échoué lamentable) de casse le bot",
    color: {
      "background-color": "#FF9A8B",
      "background-image": "linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)"
    },
    hidden: true,
    point: 10
  },

  {
    key: 'konami',
    icon: 'fad fa-gamepad',
    title: 'Konami Code',
    description: "Trouver le Konami code (↑↑↓↓←→←→BA)",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    hidden: true,
    point: 10
  },

  {
    key: 'egg',
    icon: 'fad fa-egg',
    title: 'L\'oeuf ou la poule',
    description: "Vous avez trouver un easter egg 🥚",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    hidden: true,
  },

  // STATISTIQUE

  // viewtime
  {
    icon: 'fad fa-clock',
    title: 'Toujours-là',
    description: "Atteignez un total de 60 minutes passer sur le stream",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 1,
    require: {
      field: 'viewtime',
      value: 60,
      type: 'hour'
    },
    point: 10
  },

  {
    icon: 'fad fa-clock',
    title: 'Toujours-là',
    description: "Atteignez un total de 10 heures passer sur le stream",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 2,
    require: {
      field: 'viewtime',
      value: 60 * 10,
      type: 'hour'
    },
    point: 25
  },

  {
    icon: 'fad fa-clock',
    title: 'Toujours-là',
    description: "Atteignez un total de 100 heures passer sur le stream",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 3,
    require: {
      field: 'viewtime',
      value: 60 * 100,
      type: 'hour'
    },
    point: 50
  },

  {
    icon: 'fad fa-clock',
    title: 'Toujours-là',
    description: "Atteignez un total de 250 heures passer sur le stream",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 4,
    require: {
      field: 'viewtime',
      value: 60 * 250,
      type: 'hour'
    },
    point: 100
  },

  // play

  {
    icon: 'fad fa-play',
    title: 'Je participe',
    description: "Participez 3 jours different ❗play",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 1,
    require: {
      field: 'play',
      value: 3,
    },
    point: 10
  },

  {
    icon: 'fad fa-play',
    title: 'Je participe',
    description: "Participez 10 jours different ❗play",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 2,
    require: {
      field: 'play',
      value: 10,
    },
    point: 25
  },

  {
    icon: 'fad fa-play',
    title: 'Je participe',
    description: "Participez 25 jours different ❗play",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 3,
    require: {
      field: 'play',
      value: 25,
    },
    point: 50
  },

  {
    icon: 'fad fa-play',
    title: 'Je participe',
    description: "Participez 100 jours different ❗play",
    color: {
      "background-color": "#8BC6EC",
      "background-image": "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)"
    },
    level: 4,
    require: {
      field: 'play',
      value: 100,
    },
    point: 100
  },

  // point

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 5,000 asticots",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    level: 1,
    require: {
      field: 'points',
      value: 5_000
    },
    point: 10
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 25,000 asticots",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    level: 2,
    require: {
      field: 'points',
      value: 25_000
    },
    point: 25
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 50,000 asticots",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    level: 3,
    require: {
      field: 'points',
      value: 50_000
    },
    point: 50
  },

  {
    icon: 'fad fa-coin',
    title: 'Ruée vers l\'or',
    description: "Atteignez un total de 100,000 asticots",
    color: {
      "background-color": "#52c177",
      "background-image": "linear-gradient(135deg, #bdc3a3 0%, #49c36f 100%)"
    },
    level: 4,
    require: {
      field: 'points',
      value: 100_000
    },
    point: 100
  },
  

  {
    icon: 'fad fa-dice',
    title: 'Tout ou rien !',
    description: "Parrier vos asticots 10 fois (!bet)",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 1,
    require: {
      field: 'bets',
      value: 10,
    },
    point: 10
  },

  {
    icon: 'fad fa-dice',
    title: 'Tout ou rien !',
    description: "Parrier vos asticots 50 fois (!bet)",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 2,
    require: {
      field: 'bets',
      value: 50,
    },
    point: 25
  },

  //

  {
    icon: 'fad fa-gifts',
    title: 'Un grand coeur',
    description: "Offrir un totale de 5,000 asticos",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 1,
    require: {
      field: 'giftAmount',
      value: 5_000,
    },
    point: 10
  },

  {
    icon: 'fad fa-gifts',
    title: 'Un grand coeur',
    description: "Offrir un totale de 25,000 asticos",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 2,
    require: {
      field: 'giftAmount',
      value: 25_000,
    },
    point: 25
  },

  {
    icon: 'fad fa-gifts',
    title: 'Un grand coeur',
    description: "Offrir un totale de 50,000 asticos",
    color: {
      "background-color": "#FBAB7E",
      "background-image": "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)"
    },
    level: 3,
    require: {
      field: 'giftAmount',
      value: 50_000,
    },
    point: 50
  },
  


]