<template>
  <div class="home">

    <div class="page-container">

      <div class="info-container">
        <div class="info-content">
          Vous avez debloquer <span class="bold">{{ claimedAchievement.length }}</span> achievements sur un total de <span class="bold">{{ success.length }}</span>
        </div>

        <div class="info-content fake">
          Vous avez debloquer <span class="bold">{{ claimedAchievement.length }}</span> achievements sur un total de <span class="bold">{{ success.length }}</span>
        </div>

        <i class="fad fa-info"></i>
      </div>

      <div class="" v-for="{ key, name } in categories" :key="key">

      <span class="title-header">
        {{ name }}
      </span>

      <div class="success-container" v-for="succes in allSuccess.filter( x => x.category === key )" :key="succes.id"
        @click="key === 'social' ? clickSocial(succes) : _ => null" 
        >

        <div :class="`icon-container ${hasSuccess(succes) ? '' : 'disable' } level${succes.level}`" :style="succes.color">
          <div v-if="succes.class" :class="succes.class" class="class-icon"></div>
          <i v-if="succes.icon" :class="succes.icon"></i>
        </div>

        <div class="success-content">

          <div class="title">{{ succes.hidden && !succes.noHideName ? 'Achievement masquer' : succes.title }}</div>

          <div class="description">
            {{  succes.hidden ? 'Vous devez trouvez comment débloquer cet achievement' : succes.description }}
          </div>
          
        </div>

        <div class="success-objectif" v-if="succes.require">
          <div class="actual">{{ valueFormater(getStat(succes.require.field), succes.require.type) }}</div>
          <span>/</span>
          <div class="objectif">{{ valueFormater(succes.require.value, succes.require.type) }}</div>          
        </div>

        <div class="success-objectif" v-else>
          <div class="objectif"></div>          
        </div>

        <div class="success-progress" v-if="succes.require" :style="{ ...succes.color, width: percentage(succes.require) + '%' }" />

      </div>

      </div>

      <span class="title-header">
        En cours
      </span>

      <div class="success-container" v-for="succes in currentAchievement.filter( x => !x.category )" :key="succes.id">

        <div :class="`icon-container disable level${succes.level}`" :style="succes.color">
          <div v-if="succes.class" :class="succes.class" class="class-icon"></div>
          <i v-if="succes.icon" :class="succes.icon"></i>
        </div>

        <div class="success-content">

          <div class="title">{{ succes.hidden ? 'Achievement masquer' : succes.title }}</div>

          <div class="description">
            {{  succes.hidden ? 'Vous devez trouvez comment débloquer cet achievement' : succes.description }}
          </div>
          
        </div>

        <div class="success-objectif" v-if="succes.require">
          <div class="actual">{{ valueFormater(getStat(succes.require.field), succes.require.type) }}</div>
          <span>/</span>
          <div class="objectif">{{ valueFormater(succes.require.value, succes.require.type) }}</div>          
        </div>

        <div class="success-objectif" v-else>
          <div class="objectif"></div>          
        </div>

        <div class="success-progress" v-if="succes.require" :style="{ ...succes.color, width: percentage(succes.require) + '%' }" />

      </div>

      <span class="title-header">
        Obtenu
      </span>

      <div class="success-container" v-for="succes in claimedAchievement.filter( x => !x.category )" :key="succes.id">

        <div :class="`icon-container level${succes.level}`" :style="succes.color">
          <div v-if="succes.class" :class="succes.class" class="class-icon"></div>
          <i v-if="succes.icon" :class="succes.icon"></i>
        </div>

        <div class="success-content">

          <div class="title">{{ succes.title }}</div>

          <div class="description">
            {{ succes.description }}
          </div>
          
        </div>

        <div class="success-objectif" v-if="succes.require">
          <div class="actual">{{ valueFormater(getStat(succes.require.field), succes.require.type) }}</div>
          <span>/</span>
          <div class="objectif">{{ valueFormater(succes.require.value, succes.require.type) }}</div>          
        </div>

        <div class="success-objectif" v-else>
          <div class="objectif">{{ getAchivementData(succes) }}</div>          
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

import achievement from './achievements'

moment.locale('fr');

const collectionRef = db.collection('achievements');

const  runDelete = async _ => {

  const snapshot = await collectionRef.get()

  snapshot.docs.forEach( async doc => {
    await doc.ref.delete()
  })

  achievement.forEach((x, i) => {
    db.collection('achievements').add({
      publicId: i,
      ...x
    })
  })
}

// runDelete()

export default {
  name: 'Achievement',

   metaInfo () {
    return {
      title: `Succès - Alxios`
    }
  },

  async created() {
    const users = await db.collection('users').where('userId', '==', this.user.id).get()

    const statRef = await users.docs[0].ref.collection('statistics-all').doc('all')
    const achievementRef = await users.docs[0].ref.collection('achievements')

    this.$bind('stats', statRef)
    this.$bind('achievement', achievementRef)
  },

  firestore () {
    return {
      success: db.collection('achievements')
    }
  },

  data () {
    return {
      users: [],
      moment,

      categories: [
        { key: 'noel2021', name: 'Noël 2021 🎅' },
        { key: 'new', name: 'Nouveau' },
        { key: 'social', name: 'Social' },
      ],

      success: [],

      stats: {},
      achievement: [],
    }
  },

  computed: {
    ...mapState(['user']),

    claimedAchievement () {
      return this.success.filter(x => {
        if (x.require) {
          return this.getStat(x.require.field) >= x.require.value
        }
        return this.achievement.find(y => y.key == x.key) ? true : false
      }).sort((a, b ) => a.publicId - b.publicId)
    },

    allSuccess() {
      const lasts = {}

      return this.success.filter(x => {
        return this.claimedAchievement.find(y => y.publicId == x.publicId) ? true : true
      }).sort((a, b ) => a.publicId - b.publicId)
        .sort((a, b ) => {

          if (a.require && (!lasts[a.title] || lasts[a.title] == a.level)) {
            lasts[a.title] = a.level

            if (b.require) {
              return this.percentage(b.require) - this.percentage(a.require)
            }
            return -1
          } else {
            return 1
          }

        })
    },

    currentAchievement () {

      const lasts = {}

      return this.success.filter(x => {
        return this.claimedAchievement.find(y => y.publicId == x.publicId) ? false : true
      }).sort((a, b ) => a.publicId - b.publicId)
        .sort((a, b ) => {

          if (a.require && (!lasts[a.title] || lasts[a.title] == a.level)) {
            lasts[a.title] = a.level

            if (b.require) {
              return this.percentage(b.require) - this.percentage(a.require)
            }
            return -1
          } else {
            return 1
          }

        })
      
    }

  },

  // ban 
  // premiere boules

  // equipe rouge
  // equipe bleu
  // equipe jaune

  // faire un bet
  // gift des boules

  // avoir participer a l'event de noel 

  // achetez un jeu sur la boutique

  // dire bonjour 

  // viewtime 1h 10h 100h 250h
  // point  5k, 25k, 50k, 100k

  methods: {

    async clickSocial (social) {
      const tag = social.key.substr(7)
      const access_token = localStorage.getItem('access_token')

      window.open(social.url,'_blank');

      axios.post('https://us-central1-alxios-christmas.cloudfunctions.net/socialSucces', {
        social: tag,
        access_token,
      })
    },

    hasSuccess (success) {
      if (success.require) {
        return this.getStat(success.require.field) >= success.require.value
      }
      return this.achievement.find(y => y.key == success.key) ? true : false
    },

    getAchivementData ({ key }) {
      const achievement = this.achievement.find(x => x.key == key)
      return moment(achievement.createdAt.seconds * 1000).calendar('DD/MM/YYYY')
    },

    getStat(key) {
      const value = this.stats[key]
      if (!value) return 0
      if (typeof value == 'object') return Object.keys(value).length
      return Math.round(Number(value))
    },

    valueFormater (value, type, sufix = true) {
      if (type == 'hour') {
        return Number(value / 60).toFixed(2).replace('.', ',').replace(',00', '') + ' h'
      } else {
        if (value >= 1_000) return Number(value / 1_000).toFixed(2).replace('.', ',').replace(',00', '') + ' k'
        return value
      }
    },

    percentage(require) {
      if (!require) return 0

      const { field, value } = require
      const current = this.getStat(field)
      const percentage = (current / value) * 100
      return percentage > 100 ? 100 : percentage
    }

  },
  
}

</script>

<style lang="scss" scoped>

.info-container {
  position: relative;

  padding: 18px 24px;
  border-radius: 10px;
  color: white;
  margin-bottom: 42px;
  font-size: 15px;
  line-height: 18px;

  overflow: hidden;

  background-color: rgb(139, 198, 236);
  background-image: linear-gradient(135deg, rgb(139, 198, 236) 0%, rgb(149, 153, 226) 100%);

  box-shadow: #00000033 0px 2px 8px 0px;

  &::after {
    color: rgb(139, 198, 236);
    position: absolute;
    top: -2px;
    font-weight: 700;
    letter-spacing: 2px;
    left: 24px;
    text-transform: uppercase;
    background: #1e1e1e;
    border: 2px solid rgb(139, 198, 236);
    padding: 4px 8px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    content: 'Information';
  }

  svg {
    position: absolute;
    top: 0;
    left: 49.5%;
    height: 100%;
    width: 100%;
    color: rgb(139, 198, 236);
    z-index: 0;
  }

  .info-content {
    margin-top: 22px;

    z-index: 1;
    opacity: 0;

    line-height: 20px;

    text-shadow: 1px 1px 6px #00000059;

    .bold {
      font-weight: 700;
      font-size: 16px;
      margin: 0 2px;
    }

    &.fake {
      opacity: 1;
      position: absolute;
      top: 18px;
      margin-right: 24px;
    }
  }
}


.success-container {
  position: relative;
  background: #171717;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin: 12px 0;
  overflow: hidden;

  transition: .3s ease all;

  cursor: pointer;

  .success-progress {
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;

    background: #7283ac;
  }

  &:hover {
    background: lighten($color: #171717, $amount: 5)
  }

  .success-content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .success-objectif {
    display: flex;
    align-items: baseline;
    margin-left: 24px;

    margin-right: 12px;

    .actual {
      white-space: nowrap;
      color: white;
      font-weight: 700;
    }

    span {
      margin: 0 4px;
    }

    .objectif {
      white-space: nowrap;
      font-size: 13px;
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    border-radius: 5px;
    margin-right: 16px;

    position: relative;

    .class-icon {
      height: 48px;
      width: 48px;
      opacity: 1;
      background-size: contain;
    }

    .encre {
      background-image: url(/encre.png);
    }

    .skerax {
      height: 40px;
      width: 40px;
      background-image: url(/skerax.png);
    }

    .aife {
      height: 40px;
      width: 40px;
      background-image: url(/aife.png);
    }

    .alxios {
      background-image: url(/alxios.png);
    }

    &.level1::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'I';
    }

    &.level2::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'II';
    }

    &.level3::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'III';
    }

    &.level4::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'IV';
    }

    &.level5::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'V';
    }

    &.disable {
      opacity: 0.3;
    }

    svg {
      color: white;
      width: 28px;
      height: 28px;
    }
  }

  .title {
    color: white;
    letter-spacing: .6px;
    font-weight: 700;
  }

  .description {
    white-space: nowrap;
    margin-top: 4px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message-info {
  width: 100%;
  text-align: center;

  margin-top: 24px;

  background: #202225;
  padding: 14px 18px;
  border-radius: 5px;

  svg {
    margin-right: 8px;
  }
}

@media screen and (max-width: 900px) {
  .title-header {
    align-items: flex-start !important;
    text-align: left !important;
    flex-direction: column !important;

    .title-count {
      margin-top: 12px;
    }
  }

  .home {
    max-height: calc(100vh) !important;
    padding-bottom: 84px;
  }

  .request {
    flex-direction: column !important;
    height: unset !important;

    div {
      margin: 0 !important;
      text-align: left !important;
      width: 100% !important;
      height: 24px;

      margin-left: 24px !important;
    }

    .title-id {
      margin-top: 8px !important;
      margin-bottom: 4px !important;
      display: block !important;
    }

    .price {
      margin-bottom: 4px !important;
    }

    .title-name {
      display: none !important;
    }

  }
}

.title-id {
  display: none !important;
}

.requests-list {
  margin-top: 42px;
}

.request {
  height: 84px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;

  background: #37393e;

  border-radius: 5px;

  overflow: hidden;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .title-name {
    margin-left: 24px;
    margin-right: 24px;

    width: 15%;
    color: white;
  }

  .buyat {
    font-weight: 300;

    width: 25%;

    b {
      color: white;
      text-transform: lowercase;
      margin-left: 6px;
    }
  }

  .price {
    text-align: right;
    width: 55%;

    b {
      color: white;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .status {
    width: 30%;
    padding-right: 32px;
    text-align: right;
  }

  .img {
    width: 84px;
    height: 84px;

    background-position: center;
    background-size: cover;

    border-right: #202225 1px solid;
    margin-right: 18px;
  }

}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  margin-top: 32px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
  padding-bottom: 32px;
}

@media screen and (max-width: 1500px) {
  .page-container {
      max-width: 1225px;
  }
}

@media screen and (max-width: 1225px) {
  .page-container {
      max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .page-container {
      max-width: 610px;
  }
}


@media screen and (max-width: 780px) {
  .page-container {
      max-width: 610px;
  }
}

</style>

